import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import 'es6-promise/auto'
import store from './store'
import { ModalPlugin } from 'bootstrap-vue'
import Sparkline from 'vue-sparklines'
import JsonCSV from 'vue-json-csv'
import VueSelectImage from 'vue-select-image'
import VueCryptojs from 'vue-cryptojs'
import VuePapaParse from 'vue-papa-parse'

import router from './router'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(require('vue-moment'));
Vue.use(VueRouter)
Vue.use(store)
Vue.use(ModalPlugin)
Vue.use(require('vue-moment'));
Vue.use(Sparkline)
Vue.use(VueSelectImage)
Vue.use(VueCryptojs)
Vue.use(VuePapaParse)
Vue.component('downloadCsv', JsonCSV)

// require('vue-select-image/dist/vue-select-image.css')

import './assets/custom.scss';

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common['Authorization'] = store.getters.accessToken;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const refreshAuthLogic = (failedRequest) =>
    axios.post("/user/refresh", {}, {
      headers: {
        "Authorization": store.getters.refreshToken,
        "Content-Type": "application/json"
      }
    }).then(async function (tokenRefreshResponse) {
      store.commit("saveAccessToken", tokenRefreshResponse.data.accessToken);
      store.commit("saveRefreshToken", tokenRefreshResponse.data.refreshToken);
      failedRequest.response.config.headers['Authorization'] = tokenRefreshResponse.data.accessToken;
      return Promise.resolve();
    }).catch(async function (error) {
      router.push({
        name: "Login"
      });
      return Promise.reject(error);
    });

createAuthRefreshInterceptor(axios, refreshAuthLogic);

axios.interceptors.request.use(async request => {
  if (process.env.NODE_ENV === "development") {
    console.log('Starting Request', JSON.stringify(request, null, 2))
  }
  return request
})

axios.interceptors.response.use(response => {
  if (process.env.NODE_ENV === "development") {
    console.log('Response:', JSON.stringify(response, null, 2))
  }
  return response
}, function (error) {
  console.log('Error:', JSON.stringify(error.response, null, 2))
  return error;
});

store.commit("saveStartDate", null);
store.commit("saveEndDate", null);
store.commit("saveStartDate2", null);
store.commit("saveEndDate2", null);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        userEmail: "",
        userUuid: "",
        refreshToken: "",
        accessToken: "",
        platforms: {},
        platformAccountTimezones: {},
        platformAccounts: {},
        mediaBuyers: {},
        platformUuid: "",
        platformAccountTimezoneUuid: "",
        platformAccountUuid: "",
        platformCampaignUuid: "",
        dateRange: {
            startDate: new Date(),
            endDate: new Date(),
        },
        dateRange2: {
            startDate: new Date(),
            endDate: new Date(),
        },            
        widgetFilter: "",
        campaignFilter: "",
        countries: [],
        verticals: [],
        languages: [],
        iabCategories: [],
        partners: [],
        leadTypes: [],
        users: [],
        tags: [],
        actors: [],
        editors: [],
        scripts: [],
        texts: [],
        images: [],
        brands: [],
    },
    mutations: {
        logoutState (state, payload) {
            state = payload
        },                
        saveAccessToken (state, payload) {
            state.accessToken = payload
        },        
        saveRefreshToken (state, payload) {
            state.refreshToken = payload
        },
        saveUserEmail (state, payload) {
            state.userEmail = payload
        },
        saveUserUuid (state, payload) {
            state.userUuid = payload
        },        
        savePlatformUuid (state, payload) {
            state.platformUuid = payload
        },
        savePlatformAccountTimezoneUuid (state, payload) {
            state.platformAccountTimezoneUuid = payload
        },
        savePlatformAccountUuid (state, payload) {
            state.platformAccountUuid = payload
        },
        saveCampaignUuid (state, payload) {
            state.platformCampaignUuid = payload
        },        
        saveStartDate (state, payload) {
            state.dateRange.startDate = payload
        },
        saveEndDate (state, payload) {
            state.dateRange.endDate = payload
        },                                
        saveStartDate2 (state, payload) {
            state.dateRange2.startDate = payload
        },
        saveEndDate2 (state, payload) {
            state.dateRange2.endDate = payload
        },         
        saveWidgetFilter (state, payload) {
            state.widgetFilter = payload
        },         
        saveSubWidgetFilter (state, payload) {
            state.subWidgetFilter = payload
        },                 
        saveCampaignFilter (state, payload) {
            state.campaignFilter = payload
        },                 
        saveTagFilter (state, payload) {
            state.tagFilter = payload
        },             
        saveCampaignCategory (state, payload) {
            state.campaignCategory = payload
        },                        
        saveContentFilter (state, payload) {
            state.contentFilter = payload
        },         
        saveGroupFilter (state, payload) {
            state.groupFilter = payload
        },                 
        savePlatforms (state, payload) {
            state.platforms = payload
        },                         
        savePlatformAccountTimezones (state, payload) {
            state.platformAccountTimezones = payload
        },
        savePlatformAccounts (state, payload) {
            state.platformAccounts = payload
        },
        saveMediaBuyers (state, payload) {
            state.mediaBuyers = payload
        },     
        saveLanguages (state, payload) {
            state.languages = payload
        },           
        saveiabCategories (state, payload) {
            state.iabCategories = payload
        },                   
        saveBrands (state, payload) {
            state.brands = payload
        },                           
        saveCountries (state, payload) {
            state.countries = payload
        },             
        saveVerticals (state, payload) {
            state.verticals = payload
        },                           
        savePartners (state, payload) {
            state.partners = payload
        },                         
        saveLeadTypes (state, payload) {
            state.leadTypes = payload
        },                             
        saveTags (state, payload) {
            state.tags = payload
        },                                                                  
        saveUsers (state, payload) {
            state.users = payload
        },             
        saveActors (state, payload) {
            state.actors = payload
        },             
        saveEditors (state, payload) {
            state.editors = payload
        },                                                                          
        saveScripts (state, payload) {
            state.scripts = payload
        },                                                                          
        saveTexts (state, payload) {
            state.texts = payload
        },                                                                          
        saveImages (state, payload) {
            state.images = payload
        },                                                                                                  
    },
    getters: {  
        refreshToken: state => {
            return state.refreshToken
        },        
        accessToken: state => {
            return state.accessToken
        },        
        userEmail: state => {
            return state.userEmail
        },  
        userUuid: state => {
            return state.userUuid
        },                
        platformUuid: state => {
            return state.platformUuid
        },        
        platformAccountTimezoneUuid: state => {
            return state.platformAccountTimezoneUuid
        },        
        platformAccountUuid: state => {
            return state.platformAccountUuid
        },        
        platformCampaignUuid: state => {
            return state.platformCampaignUuid
        },                
        startDate: state => {
            return state.dateRange.startDate
        },                      
        endDate: state => {
            return state.dateRange.endDate
        },                              
        startDate2: state => {
            return state.dateRange2.startDate
        },                      
        endDate2: state => {
            return state.dateRange2.endDate
        },                                      
        widgetFilter: state => {
            return state.widgetFilter
        },                                  
        subWidgetFilter: state => {
            return state.subWidgetFilter
        },                                              
        campaignFilter: state => {
            return state.campaignFilter
        },          
        tagFilter: state => {
            return state.tagFilter
        },                        
        campaignCategory: state => {
            return state.campaignCategory
        },                                
        contentFilter: state => {
            return state.contentFilter
        },             
        groupFilter: state => {
            return state.groupFilter
        },                        
        platforms: state => {
            return state.platforms
        },             
        platformAccountTimezones: state => {
            return state.platformAccountTimezones
        },             
        platformAccounts: state => {
            return state.platformAccounts
        },            
        mediaBuyers: state => {
            return state.mediaBuyers
        },
        countries: state => {
            return state.countries
        },
        languages: state => {
            return state.languages
        },        
        iabCategories: state => {
            return state.iabCategories
        },
        brands: state => {
            return state.brands
        },
        verticals: state => {
            return state.verticals
        },
        partners: state => {
            return state.partners
        },               
        leadTypes: state => {
            return state.leadTypes
        },                                                                                               
        users: state => {
            return state.users
        },  
        tags: state => {
            return state.tags
        },     
        actors: state => {
            return state.actors
        },     
        editors: state => {
            return state.editors
        },     
        scripts: state => {
            return state.scripts
        },     
        texts: state => {
            return state.texts
        },             
        images: state => {
            return state.images
        },             
    }    
})

<template>
  <div>
    <NavBar v-if="!(this.$route.name === 'Login')" />
    <router-view />
    <b-container fluid class="pt-0">
      <b-row align-h="end">
        <b-col cols="12" lg="12" align-self="center" class="text-left mt-3 mb-4 pl-4" v-bind:class="{ 'text-center': this.$route.name === 'Login', 'text-left': !this.$route.name === 'Login' }"><a href="https://adfluential.com" target="_blank" style="color:#000000; text-decoration: none;">&copy; Adfluential Limited 2024</a></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";

export default {
  name: "App",
  components: {
    NavBar,
  },
};
</script>

<style lang="scss">
  html {
    font-size: 14px;
  }
</style>